import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const userRoutes = [
  {
    path: '',
    children: [
      {
        path: '',
        element: <Users />
      },
      {
        path: 'single',
        children: [
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  }
];

export default userRoutes;
