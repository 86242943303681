import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const SystemParameter = Loader(
  lazy(() => import('src/content/settings/SystemParameter'))
);

const systemParameterRoutes = [
  {
    path: '',
    element: <SystemParameter />
  }
];

export default systemParameterRoutes;
