import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

function SuspenseLoader({
  position = 'fixed',
  dataTest
}: {
  position?: 'fixed' | 'relative' | 'absolute';
  dataTest?: String;

}) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: position,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-test={dataTest || 'loader'}
    >
      <CircularProgress size={64} disableShrink thickness={3} data-test="suspense-loader" />
    </Box>
  );
}

export default SuspenseLoader;
