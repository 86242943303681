/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const parseInstruction = /* GraphQL */ `
  query ParseInstruction($s3Key: String!) {
    parseInstruction(s3Key: $s3Key)
  }
`;
export const getKeywordsFromQuestion = /* GraphQL */ `
  query GetKeywordsFromQuestion($question: String!) {
    getKeywordsFromQuestion(question: $question)
  }
`;
export const startNewConversation = /* GraphQL */ `
  query StartNewConversation($question: String!, $instructionId: String!) {
    startNewConversation(question: $question, instructionId: $instructionId)
  }
`;
export const sendNewMessageToConversation = /* GraphQL */ `
  query SendNewMessageToConversation(
    $question: String!
    $conversationId: String!
  ) {
    sendNewMessageToConversation(
      question: $question
      conversationId: $conversationId
    )
  }
`;
export const getPromptPreview = /* GraphQL */ `
  query GetPromptPreview(
    $PAGES_MAX_PER_QUESTION: Int!
    $PREVIOUS_MESSAGES_MAX: Int!
    $PAGES_OFFSET: Int!
    $OPENSEARCH_RESULTS_MAX: Int!
    $MODEL_ALLOW_16K: Int!
    $PROMPT_CONVERSATION_START: String!
  ) {
    getPromptPreview(
      PAGES_MAX_PER_QUESTION: $PAGES_MAX_PER_QUESTION
      PREVIOUS_MESSAGES_MAX: $PREVIOUS_MESSAGES_MAX
      PAGES_OFFSET: $PAGES_OFFSET
      OPENSEARCH_RESULTS_MAX: $OPENSEARCH_RESULTS_MAX
      MODEL_ALLOW_16K: $MODEL_ALLOW_16K
      PROMPT_CONVERSATION_START: $PROMPT_CONVERSATION_START
    )
  }
`;
export const removeInstruction = /* GraphQL */ `
  query RemoveInstruction($instructionId: String!) {
    removeInstruction(instructionId: $instructionId)
  }
`;
export const searchInstructionCustom = /* GraphQL */ `
  query SearchInstructionCustom($questionText: String!, $tags: [String!]) {
    searchInstructionCustom(questionText: $questionText, tags: $tags)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      first_name
      email
      owner
      token_amount_spent
      token_amount_assigned
      groups
      avatar_url
      cognito_id
      customer_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first_name
        email
        owner
        token_amount_spent
        token_amount_assigned
        groups
        avatar_url
        cognito_id
        customer_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        first_name
        email
        owner
        token_amount_spent
        token_amount_assigned
        groups
        avatar_url
        cognito_id
        customer_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTokenTransaction = /* GraphQL */ `
  query GetTokenTransaction($id: ID!) {
    getTokenTransaction(id: $id) {
      id
      type
      token_amount
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTokenTransactions = /* GraphQL */ `
  query ListTokenTransactions(
    $filter: ModelTokenTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        token_amount
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTokenTransactions = /* GraphQL */ `
  query SyncTokenTransactions(
    $filter: ModelTokenTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTokenTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        token_amount
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTokenProduct = /* GraphQL */ `
  query GetTokenProduct($id: ID!) {
    getTokenProduct(id: $id) {
      id
      stripe_id
      token_amount
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTokenProducts = /* GraphQL */ `
  query ListTokenProducts(
    $filter: ModelTokenProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripe_id
        token_amount
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTokenProducts = /* GraphQL */ `
  query SyncTokenProducts(
    $filter: ModelTokenProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTokenProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        stripe_id
        token_amount
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSystemParameter = /* GraphQL */ `
  query GetSystemParameter($id: ID!) {
    getSystemParameter(id: $id) {
      id
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSystemParameters = /* GraphQL */ `
  query ListSystemParameters(
    $filter: ModelSystemParameterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSystemParameters = /* GraphQL */ `
  query SyncSystemParameters(
    $filter: ModelSystemParameterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInstruction = /* GraphQL */ `
  query GetInstruction($id: ID!) {
    getInstruction(id: $id) {
      id
      title
      summary
      tags
      security_classification
      echelon
      name
      subject
      effective_date
      us_navy_status
      system_status
      sponsor
      form
      s3_key
      conversation_count
      cancelled_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstructions = /* GraphQL */ `
  query ListInstructions(
    $filter: ModelInstructionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstructions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        summary
        tags
        security_classification
        echelon
        name
        subject
        effective_date
        us_navy_status
        system_status
        sponsor
        form
        s3_key
        conversation_count
        cancelled_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstructions = /* GraphQL */ `
  query SyncInstructions(
    $filter: ModelInstructionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        summary
        tags
        security_classification
        echelon
        name
        subject
        effective_date
        us_navy_status
        system_status
        sponsor
        form
        s3_key
        conversation_count
        cancelled_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchInstructions = /* GraphQL */ `
  query SearchInstructions(
    $filter: SearchableInstructionFilterInput
    $sort: [SearchableInstructionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInstructionAggregationInput]
  ) {
    searchInstructions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        summary
        tags
        security_classification
        echelon
        name
        subject
        effective_date
        us_navy_status
        system_status
        sponsor
        form
        s3_key
        conversation_count
        cancelled_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getInstructionPage = /* GraphQL */ `
  query GetInstructionPage($id: ID!) {
    getInstructionPage(id: $id) {
      id
      s3_key
      page_number
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listInstructionPages = /* GraphQL */ `
  query ListInstructionPages(
    $filter: ModelInstructionPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstructionPages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        s3_key
        page_number
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInstructionPages = /* GraphQL */ `
  query SyncInstructionPages(
    $filter: ModelInstructionPageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructionPages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        s3_key
        page_number
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchInstructionPages = /* GraphQL */ `
  query SearchInstructionPages(
    $filter: SearchableInstructionPageFilterInput
    $sort: [SearchableInstructionPageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInstructionPageAggregationInput]
  ) {
    searchInstructionPages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        s3_key
        page_number
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      title
      instruction {
        id
        title
        summary
        tags
        security_classification
        echelon
        name
        subject
        effective_date
        us_navy_status
        system_status
        sponsor
        form
        s3_key
        conversation_count
        cancelled_date
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      initial_question
      messages {
        items {
          id
          user_input
          response_ai
          current_score
          like
          timesCopied
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conversationMessagesId
        }
        nextToken
        startedAt
      }
      owner
      pages_queue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conversationInstructionId
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        instruction {
          id
          title
          summary
          tags
          security_classification
          echelon
          name
          subject
          effective_date
          us_navy_status
          system_status
          sponsor
          form
          s3_key
          conversation_count
          cancelled_date
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        initial_question
        messages {
          nextToken
          startedAt
        }
        owner
        pages_queue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conversationInstructionId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConversations = /* GraphQL */ `
  query SyncConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        instruction {
          id
          title
          summary
          tags
          security_classification
          echelon
          name
          subject
          effective_date
          us_navy_status
          system_status
          sponsor
          form
          s3_key
          conversation_count
          cancelled_date
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        initial_question
        messages {
          nextToken
          startedAt
        }
        owner
        pages_queue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conversationInstructionId
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      user_input
      response_ai
      current_score
      like
      timesCopied
      owner
      score_events {
        items {
          id
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messageScore_eventsId
          writeUpScore_eventsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conversationMessagesId
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_input
        response_ai
        current_score
        like
        timesCopied
        owner
        score_events {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conversationMessagesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_input
        response_ai
        current_score
        like
        timesCopied
        owner
        score_events {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conversationMessagesId
      }
      nextToken
      startedAt
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessageFilterInput
    $sort: [SearchableMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMessageAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user_input
        response_ai
        current_score
        like
        timesCopied
        owner
        score_events {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conversationMessagesId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getScoreEvent = /* GraphQL */ `
  query GetScoreEvent($id: ID!) {
    getScoreEvent(id: $id) {
      id
      type
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messageScore_eventsId
      writeUpScore_eventsId
    }
  }
`;
export const listScoreEvents = /* GraphQL */ `
  query ListScoreEvents(
    $filter: ModelScoreEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messageScore_eventsId
        writeUpScore_eventsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncScoreEvents = /* GraphQL */ `
  query SyncScoreEvents(
    $filter: ModelScoreEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncScoreEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messageScore_eventsId
        writeUpScore_eventsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getWriteUp = /* GraphQL */ `
  query GetWriteUp($id: ID!) {
    getWriteUp(id: $id) {
      id
      version
      extra_prompt
      content
      current_score
      owner
      score_events {
        items {
          id
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          messageScore_eventsId
          writeUpScore_eventsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWriteUps = /* GraphQL */ `
  query ListWriteUps(
    $filter: ModelWriteUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWriteUps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        version
        extra_prompt
        content
        current_score
        owner
        score_events {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWriteUps = /* GraphQL */ `
  query SyncWriteUps(
    $filter: ModelWriteUpFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWriteUps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        version
        extra_prompt
        content
        current_score
        owner
        score_events {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
