import {
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
  Badge,
  Box
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        width:${theme.spacing(5)};
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
        & img{
          width: 100%;
          height: 100%;
        }
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));
const LogoSignWrapper = styled(Box)(
  ({ theme }) => `
        width: auto;
        height:${theme.spacing(5)};
`
);
const LogoImg = styled('img')(
  ({ theme }) => `
  background:  none;
`
);

function Logo() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const packageJson = require('../../../package.json');
  let version = packageJson.version;
  return (
    <TooltipWrapper title={t('NavyEval Dashboard')} arrow>
      <LogoWrapper to="/dashboards">
        <Badge
          sx={{
            '.MuiBadge-badge': {
              fontSize: theme.typography.pxToRem(11),
              right: -2,
              top: 8
            }
          }}
          overlap="circular"
          color="success"
          badgeContent={version}
        >
          <LogoSignWrapper>
            <LogoImg
              src="/icon.svg"
              loading="lazy"
              alt="Navy Instructions Logo"
            />
          </LogoSignWrapper>
        </Badge>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
