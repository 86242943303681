import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { InstructionsProvider } from 'src/contexts/InstructionsContext';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Instructions = Loader(
  lazy(() => import('src/content/management/Instructions'))
);

const CreateInstructions = Loader(
  lazy(() => import('src/content/management/Instructions/create'))
);

const EditInstructions = Loader(
  lazy(() => import('src/content/management/Instructions/create/Edit'))
);

const instructionsRoutes = [
  {
    path: '',
    element: (
      <InstructionsProvider>
        <Instructions />
      </InstructionsProvider>
    )
  },
  {
    path: 'create',
    element: (
      <InstructionsProvider>
        <CreateInstructions />
      </InstructionsProvider>
    )
  },
  {
    path: 'edit/:instructionId',
    element: (
      <InstructionsProvider>
        <EditInstructions />
      </InstructionsProvider>
    )
  }
];

export default instructionsRoutes;
