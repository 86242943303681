import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const TermsOfUse = Loader(
  lazy(() => import('src/content/pages/Policies/TermsOfUse'))
);

const termOfUseRoutes = [
  {
    path: 'terms-of-use',
    element: <TermsOfUse />
  }
];

export default termOfUseRoutes;
