// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TokenTransactionType = {
  "PURCHASE": "PURCHASE",
  "CONSUMPTION": "CONSUMPTION"
};

const UsNavyStatus = {
  "CANCELLED": "cancelled",
  "ACTIVE": "active"
};

const ScoreEventType = {
  "LIKE": "LIKE",
  "DISLIKE": "DISLIKE",
  "COPY": "COPY",
  "PIN": "PIN"
};

const { User, TokenTransaction, TokenProduct, SystemParameter, Instruction, InstructionPage, Conversation, Message, ScoreEvent, WriteUp } = initSchema(schema);

export {
  User,
  TokenTransaction,
  TokenProduct,
  SystemParameter,
  Instruction,
  InstructionPage,
  Conversation,
  Message,
  ScoreEvent,
  WriteUp,
  TokenTransactionType,
  UsNavyStatus,
  ScoreEventType
};