import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, {
  Auth,
  DataStore,
  AuthModeStrategyType,
  Logger,
  syncExpression
} from 'aws-amplify';
import amplifyConfig from '../aws-exports';
import { User as User_OLD } from 'src/models/user';
import {
  User,
  TokenTransaction,
  TokenProduct,
  SystemParameter,
  Instruction,
  InstructionPage,
  Conversation,
  ScoreEvent,
  WriteUp,
  Message
} from '../models';
import { CognitoUser } from 'amazon-cognito-identity-js';
Amplify.configure({
  ...amplifyConfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

// Configure logger
declare global {
  var logger: any;
}
globalThis.logger = new Logger('LoggerNavy');
declare global {
  interface Window {
    LOG_LEVEL: any;
  }
}
window.LOG_LEVEL = process.env.ENV === 'production' ? 'ERROR' : 'INFO';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User_OLD | null;
  cognitoUser: CognitoUser | null;
  userData: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<void>;
  verifyCode: (username: string, code: string, name: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User_OLD | null;
    cognitoUser: CognitoUser | null;
  };
};
type UpdateUserDataAction = {
  type: 'UPDATEDATACOGNITOUSER';
  payload: {
    userData: User | null;
  };
};
type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User_OLD;
    cognitoUser: CognitoUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | UpdateUserDataAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  cognitoUser: null,
  userData: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user, cognitoUser } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      cognitoUser
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user, cognitoUser } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      cognitoUser
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
    cognitoUser: null,
    userData: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state }),
  UPDATEDATACOGNITOUSER: (
    state: AuthState,
    action: UpdateUserDataAction
  ): AuthState => {
    const { userData } = action.payload;

    return {
      ...state,
      userData
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    setDataUserCognito();
  }, [state.cognitoUser]);
  const setDataUserCognito = async () => {
    if (state && state.cognitoUser) {
      let subscription: any;
      try {
        subscription = DataStore.observeQuery(User).subscribe((snapshot) => {
          const { items } = snapshot;
          if (items.length > 0) {
            const user = items.filter(
              (u) => u.email === state.cognitoUser['attributes']['email']
            )[0];
            logger.info('user=', user);
            dispatch({
              type: 'UPDATEDATACOGNITOUSER',
              payload: {
                userData: user
              }
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        return () => subscription.unsubscribe();
      }
    }
  };
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: cognitoUser.sub,
              jobtitle: 'Lead Developer',
              avatar: cognitoUser.picture,
              email: cognitoUser.email,
              name: cognitoUser.name,
              role: cognitoUser.role,
              location: cognitoUser.location,
              username: cognitoUser.username,
              posts: cognitoUser.posts,
              coverImg: cognitoUser.coverImg,
              followers: cognitoUser.followers,
              description: cognitoUser.description
            },
            cognitoUser
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            cognitoUser: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const clientMetadata = {
      domain: document.location.host,
      appId: process.env.REACT_APP_AMPLIFY_ID
    };
    const cognitoUser = await Auth.signIn({
      username: email,
      password: password,
      validationData: clientMetadata
    });

    if (cognitoUser.challengeName) {
      console.error(`Can't login, "${cognitoUser.challengeName}" failed.`);
      return;
    }

    DataStore.configure({
      syncExpressions: [
        syncExpression(User, () => {
          return (i) => i.owner('eq', cognitoUser.username);
        }),

        // do not sync the following models
        syncExpression(Conversation, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(Message, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(TokenTransaction, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(TokenProduct, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(SystemParameter, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(Instruction, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(InstructionPage, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(ScoreEvent, () => {
          return (i) => i.id('eq', '');
        }),
        syncExpression(WriteUp, () => {
          return (i) => i.id('eq', '');
        })
      ]
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: '1',
          name: 'Allen Wood',
          avatar: '/static/images/avatars/6.jpg',
          email: 'Monte.Auer31@yahoo.com',
          jobtitle: 'Product Infrastructure Associate',
          username: 'Delphia22',
          location: 'Gislasonchester',
          role: 'admin',
          coverImg: '/static/images/placeholders/covers/1.jpg',
          followers: '667',
          description:
            'Vestibulum rutrum rutrum neque. Aenean auctor gravida sem quam pede lobortis ligula, sit amet eleifend.',
          posts: '9',
          usedToken: 30,
          totalTokens: 100
        },
        cognitoUser
      }
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    DataStore.clear();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (
    email: string,
    password: string,
    name: string
  ): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { name, email }
    });

    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (
    username: string,
    code: string,
    name: string
  ): Promise<void> => {};

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
