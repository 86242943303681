import { FC, ReactNode, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { graphqlOperation, API } from 'aws-amplify';
import { searchInstructions } from 'src/graphql/queries';
import { Instruction, SearchInstructionsQuery } from 'src/graphql/API';

// Instructions Context

interface InstructionsState {
  instructions: {
    nextToken: string;
    total: number;
    items: Instruction[] | [];
  } | null;
}

interface InstructionsContextValue extends InstructionsState {
  setInstructions: (newInstructions: {
    nextToken: string;
    total: number;
    items: Instruction[] | [];
  }) => void;
  getInstructions: (
    type: 'get' | 'add' | 'update',
    instruction?: Instruction
  ) => Promise<void>;
}

interface InstructionsProviderProps {
  children: ReactNode;
}

type SetInstructionsAction = {
  type: 'SET_INSTRUCTIONS';
  payload: {
    newInstructions: {
      nextToken: string;
      total: number;
      items: Instruction[] | [];
    };
  };
};

type Action = SetInstructionsAction;

const initialInstructionsState: InstructionsState = {
  instructions: null
};

const instructionsHandlers: Record<
  string,
  (state: InstructionsState, action: Action) => InstructionsState
> = {
  SET_INSTRUCTIONS: (
    state: InstructionsState,
    action: SetInstructionsAction
  ): InstructionsState => {
    const { newInstructions } = action.payload;
    return {
      ...state,
      instructions: newInstructions
    };
  }
};

const instructionsReducer = (
  state: InstructionsState,
  action: Action
): InstructionsState =>
  instructionsHandlers[action.type]
    ? instructionsHandlers[action.type](state, action)
    : state;

const InstructionsContext = createContext<InstructionsContextValue>({
  ...initialInstructionsState,
  setInstructions: () => Promise.resolve(),
  getInstructions: () => Promise.resolve()
});

export const InstructionsProvider: FC<InstructionsProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(
    instructionsReducer,
    initialInstructionsState
  );

  const setInstructions = async (instructions: {
    nextToken: string;
    total: number;
    items: Instruction[] | [];
  }) => {
    dispatch({
      type: 'SET_INSTRUCTIONS',
      payload: {
        newInstructions: instructions
      }
    });
  };

  async function getInstructions(
    type: 'get' | 'add' | 'update',
    instruction?: Instruction
  ) {
    // Implement the logic for getting instructions based on the type
    switch (type) {
      case 'get':
        await queryInstruction();
        break;
      case 'add':
        await addInstruction(instruction);
        break;
      case 'update':
        await updateInstruction(instruction);
        break;
      default:
        break;
    }
  }

  const updateInstruction = async (instruction: Instruction) => {
    // Implement the logic for updating an instruction
    // Retrieve the current instructions from the state
    const { instructions } = state;
    if (instructions === null) {
      // If the list is not loaded, retrieve it from the backend
      // Example code for retrieving the instructions using GraphQL API
      const response = (await API.graphql(
        graphqlOperation(searchInstructions, {
          filter: {
            and: [{ _deleted: { ne: false } }, { s3_key: { exists: true } }]
          },
          limit: 100,
          nextToken: null
        })
      )) as { data: SearchInstructionsQuery };

      const { total, items, nextToken } = response.data.searchInstructions;

      const newInstructions = {
        total: total,
        items: items,
        nextToken: nextToken
      };

      dispatch({
        type: 'SET_INSTRUCTIONS',
        payload: {
          newInstructions: {
            ...newInstructions,
            items: newInstructions.items.map((inst: Instruction) => {
              if (inst.id === instruction.id) {
                return instruction;
              }
              return inst;
            })
          }
        }
      });
    } else {
      // Update the instruction in the local state
      const newInstructions = {
        ...instructions,
        items: instructions.items.map((inst: Instruction) => {
          if (inst.id === instruction.id) {
            return instruction;
          }
          return inst;
        })
      };

      dispatch({
        type: 'SET_INSTRUCTIONS',
        payload: {
          newInstructions: newInstructions
        }
      });
    }
  };

  const addInstruction = async (instruction: Instruction) => {
    // Implement the logic for adding an instruction
    // Retrieve the current instructions from the state
    const { instructions } = state;
    if (instructions === null) {
      // If the list is not loaded, retrieve it from the backend
      // Example code for retrieving the instructions using GraphQL API
      const response = (await API.graphql(
        graphqlOperation(searchInstructions, {
          filter: {
            and: [{ _deleted: { ne: false } }, { s3_key: { exists: true } }]
          },
          limit: 100,
          nextToken: null
        })
      )) as { data: SearchInstructionsQuery };

      const { total, items, nextToken } = response.data.searchInstructions;

      const newInstructions = {
        total: total,
        items: items,
        nextToken: nextToken
      };

      const existingElement = newInstructions.items.find(
        (inst: Instruction) => inst.id === instruction.id
      );

      if (!existingElement) {
        dispatch({
          type: 'SET_INSTRUCTIONS',
          payload: {
            newInstructions: {
              ...newInstructions,
              total: newInstructions.total + 1,
              items: [instruction, ...newInstructions.items]
            }
          }
        });
      }
    } else {
      // Update the instruction in the local state
      const existingElement = instructions.items.find(
        (inst: Instruction) => inst.id === instruction.id
      );

      if (!existingElement) {
        dispatch({
          type: 'SET_INSTRUCTIONS',
          payload: {
            newInstructions: {
              ...instructions,
              total: instructions.total + 1,
              items: [instruction, ...instructions.items]
            }
          }
        });
      }
    }
  };

  const queryInstruction = async () => {
    // Implement the logic for querying instructions
    // Retrieve the current instructions from the state
    const { instructions } = state;
    if (instructions === null) {
      try {
        // If the list is not loaded, retrieve it from the backend
        // Example code for retrieving the instructions using GraphQL API
        const response = (await API.graphql(
          graphqlOperation(searchInstructions, {
            filter: {
              and: [{ _deleted: { ne: false } }, { s3_key: { exists: true } }]
            },
            limit: 100,
            nextToken: null
          })
        )) as { data: SearchInstructionsQuery };

        const { total, items, nextToken } = response.data.searchInstructions;

        const newInstructions = {
          total: total,
          items: items,
          nextToken: nextToken
        };

        dispatch({
          type: 'SET_INSTRUCTIONS',
          payload: {
            newInstructions: newInstructions
          }
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  return (
    <InstructionsContext.Provider
      value={{
        ...state,
        setInstructions,
        getInstructions
      }}
    >
      {children}
    </InstructionsContext.Provider>
  );
};

InstructionsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default InstructionsContext;
