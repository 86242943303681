import { RouteObject } from 'react-router';

import BaseLayout from 'src/layouts/BaseLayout';
import baseRoutes from './base';
import dashboardsRoutes from './dashboards';
import userRoutes from './users';
import Authenticated from 'src/components/Authenticated';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import accountRoutes from './account';
import termOfUseRoutes from './policies';
import instructionsRoutes from './instructions';
import systemParameterRoutes from './systemParameter';

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'dashboards',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: dashboardsRoutes
  },
  {
    path: 'users',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: userRoutes
  },
  {
    path: 'instructions',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: instructionsRoutes
  },
  {
    path: 'system-parameter',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: systemParameterRoutes
  },
  {
    path: 'policies',
    children: termOfUseRoutes
  }
];

export default router;
